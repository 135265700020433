import { CSSProperties } from 'react';

import { Box, Typography } from '@mui/material';

import { UserThirdParty } from '@libs/auth';

import { SubEntityPreview } from '@api/user/getTpLinkStatus';

import { AvatarWithBorder } from '../AvatarWithBorder';

type AccountPreviewProps = {
    account: UserThirdParty | SubEntityPreview;
    style?: CSSProperties;
};

export function AccountPreview({ account, style }: AccountPreviewProps) {
    const isThirdParty = isThirdPartyUser(account);
    const username = isThirdParty ? account.username : account.title;
    return (
        <Box
            sx={{
                display: 'flex',
                gap: '1.5rem',
                alignItems: 'center',
                ...style,
            }}
        >
            <AvatarWithBorder
                imageUrl={isThirdParty ? account.profile_picture_url : account.logo_url}
                borderVariant={isThirdParty ? 'instagram' : 'default'}
            />
            <Box>
                <Typography sx={{ color: '#192434' }}>
                    {isThirdParty ? '@' : ''}
                    {username}
                </Typography>
                <Typography sx={{ color: 'rgba(25, 36, 52, 0.50);', textTransform: 'capitalize' }}>
                    {isThirdParty === false ? 'Afffilae' : account.source}
                </Typography>
            </Box>
        </Box>
    );
}

function isThirdPartyUser(account: UserThirdParty | SubEntityPreview): account is UserThirdParty {
    return 'username' in account;
}

/* eslint-disable camelcase */
import { checkStatus, getAPIBaseUrl } from '@api/common/utils';

export async function getInstagramAccessToken(code: string) {
    const res = await fetch(getAPIBaseUrl() + 'common/users/oauth.signin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            provider: 'instagram',
            code,
        }),
    });

    await checkStatus(res);
    return (await res.json()).accessToken as string;
}

import { Feed } from '@components/advertiser/feeds/feedsStore/useProductSearchFeedsStore';

import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { checkStatus, getAPISubUrlForAdvertiser } from '@api/common/utils';

export async function feedForProductSearchInfo(feedId: MongoId): Promise<Feed> {
    const url = getAPISubUrlForAdvertiser() + `/productsSearch/feed.info?id=${feedId}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });
    await checkStatus(response, 200);
    const body = await response.json();
    return body.feeds.data;
}

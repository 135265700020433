import { getJwt } from '@libs/jwt';

import { checkStatus, getAPISubUrlForAdvertiser } from '@api/common/utils';

type BaseCategory = {
    _id: string;
    gender: string;
    title_en: string;
    title_fr: string;
    title_es: string;
    slug_en: string;
    slug_fr: string;
    slug_es: string;
    slug_icon: string;
};

type CategoryMain = BaseCategory & {
    category_main_title: string;
};

type CategorySub1 = BaseCategory & {
    category_main: string;
    category_main_title: string;
    category_sub_1_title: string;
};

type CategorySub2 = BaseCategory & {
    category_main: string;
    category_main_title: string;
    category_sub_1: string;
    category_sub_1_title: string;
    category_sub_2_title: string;
};

type FeedCategoriesData = {
    categoriesMain: CategoryMain[];
    categoriesSub1: CategorySub1[];
    categoriesSub2: CategorySub2[];
};

export async function feedCategoriesForProductList(): Promise<FeedCategoriesData> {
    const url = getAPISubUrlForAdvertiser() + '/productsSearch/categories.list';

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });
    await checkStatus(response, 200);
    const body = await response.json();
    return body.data;
}

import logo from './DialogNotAuth/logo-noir-190x60.png';

export function MobileViewNotAuth({
    style,
    children,
    withLogo = true,
    ...props
}: JSX.IntrinsicElements['div'] & { withLogo?: boolean }) {
    return (
        <>
            <div
                style={{
                    height: '100%',
                    background:
                        'radial-gradient(125% 125% at 0% 50%, #E5F2F8 0%, #F3F1F3 65%, #FDF1E7 100%)',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '2rem',
                    color: '00143D',
                    ...style,
                }}
                {...props}
            >
                {withLogo && (
                    <img
                        src={logo}
                        alt='affilae-logo'
                        style={{
                            marginTop: '2rem',
                            marginBottom: '3rem',
                            alignSelf: 'center',
                        }}
                    />
                )}
                {children}
            </div>
            <style>
                {/* hacked method, should use mui theme later */}
                {`
                        .MuiOutlinedInput-root {
                            background: white;
                            border-radius: 25px;
                        }
                        .MuiOutlinedInput-notchedOutline {
                            border-color: hsl(198, 67%, 94%);
                            box-shadow: 0px 2px 14px 0px rgba(166, 209, 231, 0.21);
                        }
                        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
                            border-color: hsl(198, 67%, 50%) !important;
                        }

                        .Mui-focused .MuiOutlinedInput-notchedOutline {
                            border-color: hsl(198, 67%, 70%) !important;
                        }
                        .Mui-focused.MuiInputLabel-root {
                            color: hsl(198, 67%, 70%) !important;
                        }
                        .MuiButton-root {
                            border-radius: 25px;
                        }
                    `}
            </style>
        </>
    );
}

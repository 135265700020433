import Dialog, { DialogProps } from '@mui/material/Dialog';

import { LanguageSelectorNotAuth } from '@components/nav/SideBar/LanguageSelector';

import logo from './logo-noir-190x60.png';

export function DialogNotAuth({
    children,
    withLogo = true,
    PaperProps,
    logoProps,
    ...props
}: Omit<DialogProps, 'open' | 'onclose'> & {
    withLogo?: boolean;
    logoProps?: JSX.IntrinsicElements['img'];
}) {
    const { sx: sxPaperProps, ...otherPaperProps } = PaperProps ?? {};
    const { style: styleLogoProps, ...otherLogoProps } = logoProps ?? {};

    return (
        <Dialog
            open={true}
            PaperProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '580px',
                    ...sxPaperProps,
                },
                ...otherPaperProps,
            }}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    },
                },
            }}
            {...props}
        >
            {withLogo && (
                <img
                    src={logo}
                    alt='affilae-logo'
                    style={{
                        marginBottom: '20px',
                        alignSelf: 'center',
                        marginTop: '30px',
                        ...styleLogoProps,
                    }}
                    {...otherLogoProps}
                />
            )}
            <LanguageSelectorNotAuth />

            {children}
        </Dialog>
    );
}

// todo uniformize dialog title, content & action...

export function DialogNotAuthTitle({ style, ...props }: JSX.IntrinsicElements['h1']) {
    return (
        <h1
            style={{
                paddingBottom: '1.5rem',
                fontSize: '36px',
                lineHeight: '36px',
                textAlign: 'center',
                ...style,
            }}
            {...props}
        />
    );
}

import { useTranslation } from 'react-i18next';
import { SxProps, Theme } from '@mui/system';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { KeyboardArrowDown } from '@mui/icons-material';

import { LocaleTrad } from '@libs/getSharedVar';

const LanguageSelector = ({ customSx }: { customSx: SxProps<Theme> }) => {
    const { i18n } = useTranslation();

    const defaultSx = {
        '& .MuiSelect-select': {
            color: 'white',
            marginLeft: '4px',
            backgroundColor: 'transparent',
        },
        '& .MuiSelect-icon': {
            color: 'white',
        },
    };

    const style = customSx ? customSx : defaultSx;

    return (
        <Select
            id='locale'
            sx={style}
            value={i18n.language}
            onChange={(event) => i18n.changeLanguage(event.target.value as LocaleTrad)}
            disableUnderline
            autoWidth={false}
            variant='standard'
            IconComponent={KeyboardArrowDown}
        >
            <MenuItem value='fr'>FR</MenuItem>
            <MenuItem value='en'>EN</MenuItem>
            <MenuItem value='es'>ES</MenuItem>
        </Select>
    );
};

export function LanguageSelectorNotAuth() {
    return (
        <div
            style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
            }}
        >
            <LanguageSelector customSx={{ color: 'gray' }} />
        </div>
    );
}

export default LanguageSelector;

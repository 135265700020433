import { ReactNode } from 'react';

import { BackgroundNotAuth } from '@components/notAuth/BackgroundNotAuth';
import { DialogNotAuth } from '@components/notAuth/DialogNotAuth';
import { MobileViewNotAuth } from '@components/notAuth/MobileViewNotAuth';

import useBreakpoint from '@hooks/useBreakpoint';

export function PageNotAuthLayout({ children }: { children: ReactNode }) {
    const isMobile = useBreakpoint({ breakpoint: 'sm', direction: 'down' });

    if (isMobile) {
        return <MobileViewNotAuth>{children}</MobileViewNotAuth>;
    }

    return (
        <BackgroundNotAuth>
            <DialogNotAuth>{children}</DialogNotAuth>
        </BackgroundNotAuth>
    );
}

import { z } from 'zod';

import { Feed } from '@components/advertiser/feeds/feedsStore/useProductSearchFeedsStore';

import { getJwt } from '@libs/jwt';
import { zCurrency } from '@libs/zodUtils';

import { checkStatus, getAPISubUrlForAdvertiser } from '@api/common/utils';

import {
    FeedEncoding,
    FeedFormat,
    FeedLanguage,
    FeedMapping,
    FeedSeparator,
} from '../interfacesAdvertiser';

export interface FeedAddPayload {
    program: string;
    title: string;
    url: string;
    format: FeedFormat;
    encoding: FeedEncoding;
    separator?: FeedSeparator;
    partnerships?: string[];
    mapping?: FeedMapping;
    lang?: FeedLanguage;
    notify?: boolean;
    isActive: boolean;
    isIndexed: boolean;
    isDraft: boolean;
    xmlItem?: string;
    programDisplayName?: string;
    brandOverride?: string;
}

export async function feedAdd(payload: FeedAddPayload) {
    const url = getAPISubUrlForAdvertiser() + '/feeds.create';
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    await checkStatus(response, 201);
    const body = await response.json();
    return body.feeds.data;
}

export const initUrlFeedRequestSchema = z
    .object({
        programDisplayName: z.string().min(1).max(100),
        brandOverride: z.string().min(1).max(100),
        title: z.string().min(1).max(100),
        lang: z.enum(['FR', 'EN', 'ES']),
        url: z.string().url().min(1).max(500),
        currency: zCurrency(),
        format: z.enum(['CSV', 'XML', 'JSON']),
        encoding: z.enum(['UTF-8', 'UTF-16', 'ISO-8859-1']),
        isPublic: z.boolean(),
    })
    .and(
        z.discriminatedUnion('format', [
            z.object({
                format: z.literal('CSV'),
                separator: z.enum([',', '|', ';', '\t']),
            }),
            z.object({
                format: z.literal('XML'),
                xmlItem: z.string().min(1).max(100),
            }),
            z.object({
                format: z.literal('JSON'),
            }),
        ]),
    );

export type InitUrlFeedRequestType = z.infer<typeof initUrlFeedRequestSchema>;

export async function feedForProductSearchAdd(payload: InitUrlFeedRequestType): Promise<Feed> {
    const url = getAPISubUrlForAdvertiser() + '/productsSearch/feed.create';
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    await checkStatus(response, 201);
    const body = await response.json();
    return body.feeds.data;
}

export async function feedForProductSearchList(): Promise<Feed[]> {
    const url = getAPISubUrlForAdvertiser() + '/productsSearch/feed.list';
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
    });

    await checkStatus(response, 201);
    const body = await response.json();
    return body.feeds.data;
}

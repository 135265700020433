import { useHistory } from 'react-router-dom';
import { LocationDescriptor } from 'history';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import { ArrowBack } from '@mui/icons-material';

export function GoBackBtn({
    to,
    onClick,
    ...props
}: { to?: LocationDescriptor } & IconButtonProps) {
    const history = useHistory();

    return (
        <IconButton
            {...props}
            onClick={(...args) => {
                onClick?.(...args);
                to ? history.push(to) : history.goBack();
            }}
            style={{ position: 'absolute', top: '10px', left: '10px' }}
        >
            <ArrowBack />
        </IconButton>
    );
}

import { Feed } from '@components/advertiser/feeds/feedsStore/useProductSearchFeedsStore';

import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { checkStatus, getAPISubUrlForAdvertiser } from '@api/common/utils';

import { FeedEncoding, FeedFormat, FeedLanguage, FeedSeparator } from '../interfacesAdvertiser';

export type ProductType = 'products' | 'products_top' | 'products_search';

export interface FeedUpdatePayload {
    id: string;
    title: string;
    url: string;
    format: FeedFormat;
    encoding: FeedEncoding;
    separator?: FeedSeparator;
    partnerships?: string[] | null;
    notify?: boolean;
    isActive?: boolean;
    lang?: FeedLanguage;
    isIndexed: boolean;
    isDraft: boolean;
    type: ProductType;
}

export async function feedUpdate(payload: FeedUpdatePayload): Promise<Feed> {
    const url = getAPISubUrlForAdvertiser() + '/feeds.update';

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
    await checkStatus(response, 200);
    const body = await response.json();
    return body.feeds.data;
}

export interface FeedForProductSearchUpdatePayload {
    id: MongoId;
    title?: string;
    url?: string;
    format?: FeedFormat;
    encoding?: FeedEncoding;
    separator?: FeedSeparator;
    program?: MongoId;
    lang?: FeedLanguage;
    currency?: string;
    isPrivate?: boolean;
    isDraft?: boolean;
    mapping?: MappingColumn;
}

export interface MappingColumn {
    title: string;
    salePrice: string;
    productId: MongoId;
    price: string;
    landingPage: string;
    imageLinkOptionnal: string;
    imageLink: string;
    googleCategoryId: string;
    gender: string;
    ean: string;
    description: string;
    colour: string;
    advertiserCategory: string;
    brand: string;
}

export async function feedForProductSearchUpdate(
    payload: FeedForProductSearchUpdatePayload,
): Promise<Feed> {
    const url = getAPISubUrlForAdvertiser() + '/productsSearch/feed.update';
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: getJwt() || '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    await checkStatus(response, 200);
    const body = await response.json();
    return body.feeds.data;
}

import { create } from 'zustand';

import { MongoId } from '@libs/types';

import {
    feedForProductSearchAdd,
    feedForProductSearchList,
} from '@api/advertiser/productsFeeds/feedAdd';
import { feedCategoriesForProductList } from '@api/advertiser/productsFeeds/feedCategories';
import { feedForProductSearchInfo } from '@api/advertiser/productsFeeds/feedInfo';
import {
    feedAnalyze,
    feedCategoryDetector,
    feedProductSearchTagDetector,
    FeedTagsDetectorResponse,
} from '@api/advertiser/productsFeeds/feedTags';
import {
    feedForProductSearchUpdate,
    MappingColumn,
} from '@api/advertiser/productsFeeds/feedUpdate';

enum Language {
    FR = 'FR',
    EN = 'EN',
    ES = 'ES',
}

enum Format {
    XML = 'XML',
    CSV = 'CSV',
    JSON = 'JSON',
}

enum Separator {
    Comma = ',',
    VerticalBar = '|',
    Semicolon = ';',
    Tabulation = '\t',
}

enum Encoding {
    UTF8 = 'UTF-8',
    UTF16 = 'UTF-16',
    ISO = 'ISO-8859-1',
}
export interface Feed {
    id: MongoId;
    program: MongoId;
    title: string;
    lang: Language;
    url: string;
    currency: string;
    format: Format;
    separator?: Separator;
    encoding: Encoding;
    isPrivate: boolean;
    mapping?: MappingColumn;
    xmlItem?: string;
    programDisplayName?: string;
    brandOverride?: string;
    categoriesMapping?: CategoryMapping[];
}

interface useProductSearchFeedStoreState {
    activeStepFromUrlParams: number | null;
    setActiveStepFromUrlParams: (step: number) => void;
    editFeedCategoriesStatus: string;
    setEditFeedCategoriesStatus: (status: string) => void;
    isProductSearchFeedPanelOpen: boolean;
    openProductSearchFeedPanel: () => void;
    closeProductSearchPanel: () => void;
    isEditProductSearchFeed: boolean;
    updateIsEditProductSearchFeed: (value: boolean) => void;
    feedFeature: 'productSearch' | 'partnerships' | 'topProductSelling' | null;
    updateFeedFeature: (
        value: 'productSearch' | 'partnerships' | 'topProductSelling' | null,
    ) => void;
    feeds: Feed[];
    mappingTags: FeedTagsDetectorResponse | null;
    feedCreateStatus: string;
    feedTagDetectorStatus: string;
    feedMappingColumnsStatus: string;
    feedAnalyzeStatus: string;
    error: string | null;
    fetchFeed: (feedId: MongoId) => Promise<void>;
    fetchFeedsList: () => Promise<void>;
    createFeedAndDetectColumns: (newFeed: Omit<Feed, 'id'>) => Promise<void>;
    updatePropertiesFeedAndDetectColumns: (feed: Feed) => Promise<void>;
    updateCategoriesMappingFeed: (feed: Feed) => Promise<void>;
    setFeedForMappingCategories: (feedId: MongoId) => Promise<void>;
    analyzeFeed: (feedId: MongoId) => Promise<void>;
    resetStatus: () => void;
    resetDataFeed: () => void;
    selectedFeed: Feed | Omit<Feed, 'id'> | null;
    updateSelectedFeedId: (id: MongoId) => void;
    selectedFeedId: MongoId | null;
    productUrl: string | null;
    categoriesMapping: CategoryMapping[] | null;
    categoriesList: CategoriesList | null;
}

interface CategoryMapping {
    gender: string;
    advertiser_category: string;
    categories: Categories[];
    nb_products: number;
    status?: boolean;
    is_active: boolean;
}

interface Categories {
    categories: Category[];
}

interface Category {
    category_main: CategoryId;
    category_main_title: string;
    category_sub_1: CategoryId;
    category_sub_1_title: string;
    category_sub_2: CategoryId;
    category_sub_2_title: string;
}

interface CategoryId {
    id: MongoId;
}

interface BaseCategory {
    _id: string;
    gender: string;
    slug_en: string;
    slug_es: string;
    slug_fr: string;
    slug_icon: string;
    title_en: string;
    title_es: string;
    title_fr: string;
}

interface CategoryMain extends BaseCategory {
    category_main_title: string;
}

interface CategorySub1 extends BaseCategory {
    category_main: string;
    category_main_title: string;
    category_sub_1_title: string;
}

interface CategorySub2 extends CategorySub1 {
    category_sub_1: string;
    category_sub_2_title: string;
}

export interface CategoriesList {
    categoriesMain: CategoryMain[];
    categoriesSub1: CategorySub1[];
    categoriesSub2: CategorySub2[];
}

export const useProductSearchFeedStore = create<useProductSearchFeedStoreState>((set, get) => ({
    editFeedCategoriesStatus: 'idle',
    setEditFeedCategoriesStatus: (status) => set({ editFeedCategoriesStatus: status }),
    activeStepFromUrlParams: null as number | null,
    setActiveStepFromUrlParams: (step: number) => set({ activeStepFromUrlParams: step }),
    feedFeature: null,
    isEditProductSearchFeed: false,
    isProductSearchFeedPanelOpen: false,
    feeds: [],
    selectedFeed: null,
    feedCreateStatus: 'idle',
    feedTagDetectorStatus: 'idle',
    feedMappingColumnsStatus: 'idle',
    feedAnalyzeStatus: 'idle',
    error: null,
    selectedFeedId: null,

    productUrl: null,
    mappingTags: null,
    categoriesMapping: null,
    categoriesList: null,
    // panel
    openProductSearchFeedPanel: () => set({ isProductSearchFeedPanelOpen: true }),
    closeProductSearchPanel: () => set({ isProductSearchFeedPanelOpen: false }),
    updateIsEditProductSearchFeed: (value) => set({ isEditProductSearchFeed: value }),
    updateFeedFeature: (value) => set({ feedFeature: value }),
    //data
    updateSelectedFeedId: (id) => set({ selectedFeedId: id }),
    fetchFeed: async (feedId: MongoId) => {
        try {
            const response = await feedForProductSearchInfo(feedId);
            set(() => ({
                selectedFeed: response,
                feedCreateStatus: 'success',
            }));
        } catch (error: any) {
            console.error(error);
        }
    },
    fetchFeedsList: async () => {
        try {
            const response = await feedForProductSearchList();
            set(() => ({
                feeds: response,
            }));
        } catch (error: any) {
            console.error(error);
        }
    },
    createFeedAndDetectColumns: async (newFeed) => {
        set({ feedCreateStatus: 'loading' });
        set({ selectedFeed: newFeed });
        try {
            const response = await feedForProductSearchAdd(newFeed);
            set((state) => ({
                feeds: [...state.feeds, response],
                selectedFeedId: response.id,
                productUrl: response.url,
                feedCreateStatus: 'success',
            }));
        } catch (error: any) {
            set({ error: error.message, feedCreateStatus: 'failure' });
        }

        set({ feedTagDetectorStatus: 'loading' });
        try {
            const tags = await feedProductSearchTagDetector({
                url: newFeed.url,
                lang: 'fr',
            });
            set(() => ({
                mappingTags: tags,
                feedTagDetectorStatus: 'success',
            }));
        } catch (error: any) {
            set({ error: error.message, feedTagDetectorStatus: 'failure' });
        }
    },
    updatePropertiesFeedAndDetectColumns: async (feed) => {
        set({ feedCreateStatus: 'loading' });
        set({ feedTagDetectorStatus: 'loading' });
        if (!get().selectedFeed?.mapping && !get().selectedFeed?.categoriesMapping) {
            set({ selectedFeed: feed });
        } else {
            set((state) => ({
                selectedFeed: { mapping: state.selectedFeed?.mapping, ...feed },
            }));
        }

        if (!feed.id) {
            throw new Error('Feed ID is missing');
        } else {
            try {
                await feedForProductSearchUpdate(feed);
                set({ feedCreateStatus: 'success' });
            } catch (error: any) {
                set({ error: error.message, feedCreateStatus: 'failure' });
            }
        }
        try {
            const tags = await feedProductSearchTagDetector({
                url: feed.url,
                lang: 'fr',
            });
            set(() => ({
                mappingTags: tags,
                feedTagDetectorStatus: 'success',
            }));
        } catch (error: any) {
            set({ error: error.message, feedTagDetectorStatus: 'failure' });
        }
    },

    updateCategoriesMappingFeed: async (mapping) => {
        set({ feedMappingColumnsStatus: 'loading' });
        try {
            const currentFeedId = get().selectedFeedId;
            if (!currentFeedId) {
                throw new Error(
                    'No feed ID found in the store. Make sure a feed is created or selected before updating.',
                );
            }
            const updateFeedResponse = await feedForProductSearchUpdate(mapping);
            const categoriesAnalyze = await feedCategoryDetector(currentFeedId);
            const categoriesInfoResponse = await feedForProductSearchInfo(currentFeedId);
            const categories = await feedCategoriesForProductList();
            if (categoriesAnalyze.nb_categories > 50000) {
                set({ feedMappingColumnsStatus: 'failure' });
                throw new Error(
                    'Too many categories detected (>200). Please reduce the number of categories.',
                );
            }
            set(() => ({
                categoriesMapping: categoriesInfoResponse.categoriesMapping.sort((a, b) =>
                    a.advertiser_category.localeCompare(b.advertiser_category),
                ),
                selectedFeed: updateFeedResponse,
                categoriesList: categories,
                feedMappingColumnsStatus: 'success',
            }));
        } catch (error: any) {
            set({ error: error.message, feedMappingColumnsStatus: 'failure' });
        }
    },
    analyzeFeed: async (feedId: MongoId) => {
        set({ feedAnalyzeStatus: 'loading' });
        try {
            await feedAnalyze(feedId);
            set({ feedAnalyzeStatus: 'success' });
        } catch (error: any) {
            set({ error: error.message, feedAnalyzeStatus: 'failure' });
        }
    },
    // call when we load directly the final step to edit categories mapping
    setFeedForMappingCategories: async (feedId: MongoId) => {
        set({ editFeedCategoriesStatus: 'loading' });
        try {
            get().updateFeedFeature('productSearch');
            await get().fetchFeed(feedId);
            const tags = await feedProductSearchTagDetector({
                url: get().selectedFeed?.url,
                lang: 'fr',
            });
            set({ feedTagDetectorStatus: 'success' });
            set({ mappingTags: tags });
            await get().updateCategoriesMappingFeed({
                id: feedId,
                mapping: get().selectedFeed?.mapping,
            });
            set({ editFeedCategoriesStatus: 'success' });
        } catch (error: any) {
            set({ error: error.message, feedAnalyzeStatus: 'failure' });
            set({ editFeedCategoriesStatus: 'failure' });
        }
    },
    resetStatus: () => {
        set({
            feedMappingColumnsStatus: 'idle',
            feedTagDetectorStatus: 'idle',
            feedCreateStatus: 'idle',
            feedAnalyzeStatus: 'idle',
        });
    },
    resetDataFeed: () => {
        set({
            selectedFeed: null,
            selectedFeedId: null,
            productUrl: null,
            categoriesMapping: null,
            categoriesList: null,
        });
    },
}));

interface SuccessFeedAddedDialog {
    isOpenDialogSuccessFeedAdded: boolean;
    openDialogSuccessFeedAdded: () => void;
    closeDialogSuccessFeedAdded: () => void;
}

export const useSuccessFeedAddedDialog = create<SuccessFeedAddedDialog>((set) => ({
    isOpenDialogSuccessFeedAdded: false,
    openDialogSuccessFeedAdded: () => set({ isOpenDialogSuccessFeedAdded: true }),
    closeDialogSuccessFeedAdded: () => set({ isOpenDialogSuccessFeedAdded: false }),
}));

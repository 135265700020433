import {
    DELETE_VOUCHER,
    DELETE_VOUCHER_FAILURE,
    DELETE_VOUCHER_SUCCESS,
    FETCH_VOUCHERS,
    FETCH_VOUCHERS_FAILURE,
    FETCH_VOUCHERS_SUCCESS,
    ON_RESET_VOUCHER_MESSAGE_ERROR,
} from '@actions/vouchers';

const INITIAL_STATE = {
    vouchers: {
        queryCriteria: {
            program: '',
            affiliateProfile: '',
            orderBy: 'createdAt', // 'createdAt', 'name' or 'code'
            sort: 'desc', // 'asc" or 'desc'
            page: 0,
            partnership: '',
            search: '',
            blacklisted: 'all',
            limit: 25,
        },
        list: null,
        total: 0,
        errorMessage: '',
    },
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_VOUCHERS:
            return {
                ...state,
            };
        case FETCH_VOUCHERS_SUCCESS:
            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    list: action.vouchers,
                    total: action.total,
                    queryCriteria: action.queryCriteria,
                },
            };
        case FETCH_VOUCHERS_FAILURE:
            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    queryCriteria: action.queryCriteria,
                },
            };

        case DELETE_VOUCHER:
            return {
                ...state,
            };
        case DELETE_VOUCHER_SUCCESS:
            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    voucherToDelete: '',
                },
            };
        case DELETE_VOUCHER_FAILURE:
            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    voucherToDelete: '',
                    errorMessage: action.payload.message,
                },
            };

        case ON_RESET_VOUCHER_MESSAGE_ERROR:
            return {
                ...state,
                vouchers: {
                    ...state.vouchers,
                    errorMessage: '',
                },
            };

        default:
            break;
    }

    return state;
}

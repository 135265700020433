import { Fragment, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
    closeConfirmationSuccessDialog,
    fetchLatestPaymentIntent,
    listPaymentMethods,
    openAdvertiserSubscribeDialog,
    openAdvertiserUpdatePaymentMethodDialog,
    openAdvertiserUpdateSubscriptionDialog,
} from '@actions/advertiserBilling';
import { listPlans } from '@actions/common';
import UpdatePaymentMethodDialogContainer from '@containers/advertiser/billing/UpdatePaymentMethodDialogContainer';
import _ from 'lodash';
import capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';

import { Dialog, DialogActions, DialogContent, DialogTitle, SvgIcon } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';

import { FormPageLayout, FormPageTitle } from '@components/generic/Form';
import PageTabTitle from '@components/generic/PageTabTitle/PageTabTitle';
import { Tooltip } from '@components/generic/Tooltip';

import { usePromise } from '@hooks/usePromise';

import { useAppDispatch, useAppSelector } from '@libs/reduxHooks';

import { Advertiser, latestPlans, plans } from '@api/advertiser/fetchCurrentAdvertiserData';
import { getQuota } from '@api/advertiser/getQuota';

import { hasAdvertiserCompletedDetails } from '../AdvertiserDetailsTab';
import SubscribeDialog, { yearlyDiscountPercent } from './SubscribeDialog';

type SubscriptionProps = {
    hasRedirectedToDetailsOnce: boolean;
    setHasRedirectedToDetailsOnce: (value: boolean) => void;
};

export default function SubscriptionTab({
    hasRedirectedToDetailsOnce,
    setHasRedirectedToDetailsOnce,
}: SubscriptionProps) {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const plans = useAppSelector((state) => state.common.plans);
    const advertiser = useAppSelector((state) => state.advertiser.currentAdvertiser)!;
    const areDetailsComplete = hasAdvertiserCompletedDetails(advertiser);
    const paymentMethods = useAppSelector((state) => state.advertiserBillingReducer.paymentMethods);
    const isSuccessUpgrade = useAppSelector(
        (state) => state.advertiserBillingReducer.subscribeDialog.success,
    );
    const action = useAppSelector((state) => state.advertiserBillingReducer.subscribeDialog.action);

    const billingPeriod = advertiser.billingPeriod ?? 'monthly';

    useEffect(() => {
        if (!hasRedirectedToDetailsOnce && !areDetailsComplete && !CONFIG.disableRedirect) {
            history.push(`/${i18n.language}/advertiser/billing/details`);
            setHasRedirectedToDetailsOnce(true);
        }

        if (_.isEmpty(plans)) {
            dispatch(listPlans());
        }
        if (_.isEmpty(paymentMethods)) {
            dispatch(listPaymentMethods());
        }

        dispatch(fetchLatestPaymentIntent());
    }, []);

    const [quotaLoading, quotaLoadingStart] = usePromise(async () => {
        try {
            return await getQuota();
        } catch (error) {
            window.addFlash('error', 'Fail to load Quota');
            throw error;
        }
    });

    useEffect(() => {
        quotaLoadingStart();
    }, []);

    const clickQuotaUsage = quotaLoading.data;

    if (!advertiser || _.isEmpty(plans) || quotaLoading.status !== 'success') {
        return null;
    }

    const mainSubscription = getMainSubscription(advertiser);
    const mainPlan = mainSubscription?.plans?.find(isMainPlan);

    const clickQuotaExceeded = clickQuotaUsage && clickQuotaUsage.total > advertiser.quotas.clicks;
    const clickExceeded = clickQuotaUsage?.overQuota ?? 0;

    const quotasPlan = advertiser.subscriptions
        ?.map((sub) => sub.plans)
        .flat()
        .find((plan) => plan.name === 'Quotas');
    const clickExceededPriceByUnit = quotasPlan?.amount ?? 40; // backup plan if quota exceeded when trial (should occur rarely)
    const clickExceededUnitSize = 1000;

    const paymentMethodCard = paymentMethods?.[0]?.card;

    const discountAmount = (function () {
        if (mainSubscription?.discount?.amountOff) {
            return mainSubscription.discount.amountOff;
        } else if (mainSubscription?.discount?.percentOff) {
            return mainPlan!.amount * (mainSubscription.discount.percentOff / 100);
        } else {
            return undefined;
        }
    })();

    const subscriptionPrice = (function () {
        if (mainPlan == null) return advertiser.price; // try to get rit of it

        let subscriptionPrice = mainPlan.amount;
        if (discountAmount) {
            subscriptionPrice -= discountAmount;
        }
        return subscriptionPrice;
    })();

    const clickExceededPrice = clickExceededPriceByUnit * (clickQuotaUsage?.stripeUnitUsed ?? 0);

    const fullPriceYearly = billingPeriod === 'yearly' ? subscriptionPrice : null;

    const fullPriceMonthly = (function () {
        // ⚠️ can be > 0 for yearly subscription
        // ex: when the number of clicks exceeds the quota
        let price = null; // use null instead of 0 to differentiate no billing & billing reduced to 0
        if (billingPeriod === 'monthly') {
            price = (price ?? 0) + subscriptionPrice;
        }
        if (latestPlans.includes(advertiser.plan) && clickExceededPrice > 0) {
            price = (price ?? 0) + clickExceededPrice;
        }
        return price;
    })();

    const nextBillingDateMonthly =
        mainSubscription && fullPriceMonthly && mainSubscription.currentPeriodEnd
            ? moment(mainSubscription.currentPeriodEnd).format(t('date_format'))
            : null;

    const nextBillingDateYearly =
        mainSubscription && mainPlan?.interval === 'year' && mainSubscription.currentPeriodEnd
            ? moment(mainSubscription.currentPeriodEnd).format(t('date_format'))
            : null;

    const showStripeInfo = mainSubscription != null && mainSubscription.status !== 'canceled';

    const currencyFormatter = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: advertiser.currency,
    }).format;
    const numberFormatter = new Intl.NumberFormat(i18n.language).format;

    function renderTextSuccessDialog() {
        if (advertiser.planSwitch) {
            if (advertiser.planSwitch.plan === 'track') {
                return (
                    <>
                        <span>{t('billing_subscription_dowgrade_dialog_success')}</span>
                        <b>{moment.unix(advertiser.subscribedTo).format(t('date_format'))}</b>
                    </>
                );
            } else if (advertiser.planSwitch.plan === 'engage') {
                return <span>{t('billing_subscription_upgrade_dialog_success')}</span>;
            }
        } else {
            return <span>{t('billing_subscription_subscribe_dialog_success')}</span>;
        }
    }

    function renderSuccessDialog() {
        return (
            <Dialog
                open={isSuccessUpgrade && action === 'update'}
                onClose={() => dispatch(closeConfirmationSuccessDialog())}
                PaperProps={{
                    sx: { width: '500px' },
                }}
            >
                <DialogTitle
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <SvgIcon
                        component={CheckCircleOutlineOutlinedIcon}
                        sx={{
                            backgroundColor: 'rgba(3, 120, 71, 0.2)',
                            borderRadius: '50%',
                            padding: '0.4rem',
                            marginRight: '1rem',
                            color: '#037847',
                        }}
                        fontSize='large'
                    />
                    {t('billing_subscription_update_dialog_success')}
                </DialogTitle>
                <DialogContent>{renderTextSuccessDialog()}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => dispatch(closeConfirmationSuccessDialog())}
                        variant='contained'
                        style={{ textTransform: 'capitalize' }}
                    >
                        {t('generic_ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function renderNextPlanDowngraded() {
        if (
            advertiser.planSwitch &&
            advertiser.planSwitch.plan === 'track' &&
            advertiser.planSwitch.plan !== advertiser.plan
        ) {
            return <span style={{ color: '#D47202', fontWeight: 'bold' }}>Plan TRACK</span>;
        }
    }

    return (
        <FormPageLayout>
            <PageTabTitle>{t('helmet_title_billing_subscription')}</PageTabTitle>
            <SubscribeDialog />
            <UpdatePaymentMethodDialogContainer />
            <Header />
            {fullPriceYearly != null && (
                <>
                    <Paper className='currentSubscriptionWrapper'>
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('billing_subscription_table_headers_products')}</th>
                                    <th>{t('billing_subscription_table_headers_usage')}</th>
                                    <th>{t('billing_subscription_table_headers_price')}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {billingPeriod === 'yearly' && (
                                    <>
                                        <tr>
                                            <td>
                                                <Trans
                                                    i18nKey='billing_subscription_plan_title_yearly'
                                                    values={{
                                                        plan: capitalize(advertiser.plan),
                                                        discountPercent: yearlyDiscountPercent,
                                                    }}
                                                >
                                                    ___{' '}
                                                    <span style={{ color: '#419ed8' }}>save %</span>{' '}
                                                    __
                                                </Trans>
                                            </td>
                                            <td className='quotasCell'>
                                                <QuotaLinearProgress
                                                    id='clickProgress'
                                                    title={
                                                        <Trans i18nKey='billing_subscription_click_this_month'>
                                                            clicks{' '}
                                                            <span
                                                                style={{
                                                                    color: 'grey',
                                                                    fontSize: '12px',
                                                                    fontWeight: 'normal',
                                                                }}
                                                            >
                                                                (this month)
                                                            </span>
                                                        </Trans>
                                                    }
                                                    value={Math.min(
                                                        clickQuotaUsage?.total ?? 0,
                                                        advertiser.quotas.clicks,
                                                    )}
                                                    max={advertiser.quotas.clicks}
                                                />
                                            </td>
                                            <td>
                                                {currencyFormatter(subscriptionPrice / 100) +
                                                    t('generic_by_year')}
                                            </td>
                                        </tr>
                                        {discountAmount != null && (
                                            <tr>
                                                <td colSpan={2}>
                                                    {t('billing_subscription_table_discount', {
                                                        name: mainSubscription?.discount?.name
                                                            ? mainSubscription.discount.name
                                                            : mainSubscription.discount.id,
                                                    })}
                                                </td>
                                                <td>- {currencyFormatter(discountAmount / 100)}</td>
                                            </tr>
                                        )}
                                    </>
                                )}

                                <tr>
                                    <td colSpan={2}>
                                        {t('billing_subscription_table_total_yearly')}
                                    </td>
                                    <td>
                                        {currencyFormatter(fullPriceYearly / 100) +
                                            t('generic_by_year')}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Paper>
                    {showStripeInfo && (
                        <Footer>
                            {
                                nextBillingDateYearly &&
                                    t('billing_subscription_next_billing', {
                                        date: nextBillingDateYearly,
                                        interpolation: { escapeValue: false },
                                    })
                                // need to set escapeValue: false to have good display of date
                            }
                            {renderNextPlanDowngraded()}
                        </Footer>
                    )}
                </>
            )}

            {fullPriceMonthly != null && (
                <>
                    <Paper
                        className='currentSubscriptionWrapper'
                        sx={{
                            fontSize: '14px',
                        }}
                    >
                        <table>
                            <thead>
                                <tr
                                    style={{
                                        textAlign: 'left',
                                    }}
                                >
                                    <th>{t('billing_subscription_table_headers_products')}</th>
                                    <th>{t('billing_subscription_table_headers_usage')}</th>
                                    <th>{t('billing_subscription_table_headers_price')}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {billingPeriod === 'monthly' && (
                                    <>
                                        <tr>
                                            <td>
                                                {t('billing_subscription_plan_title_monthly', {
                                                    plan: capitalize(advertiser.plan),
                                                })}
                                            </td>
                                            <td className='quotasCell'>
                                                <QuotaLinearProgress
                                                    id='clickProgress'
                                                    title={t('clicks')}
                                                    value={Math.min(
                                                        clickQuotaUsage?.total ?? 0,
                                                        advertiser.quotas.clicks,
                                                    )}
                                                    max={advertiser.quotas.clicks}
                                                />
                                            </td>
                                            <td>
                                                {currencyFormatter(subscriptionPrice / 100) +
                                                    '/' +
                                                    t('M')}
                                            </td>
                                        </tr>

                                        {discountAmount != null && (
                                            <tr>
                                                <td colSpan={2}>
                                                    {t('billing_subscription_table_discount', {
                                                        name: mainSubscription?.discount?.name
                                                            ? mainSubscription.discount.name
                                                            : mainSubscription.discount.id,
                                                    })}
                                                </td>
                                                <td>- {currencyFormatter(discountAmount / 100)}</td>
                                            </tr>
                                        )}
                                    </>
                                )}

                                {clickQuotaExceeded && latestPlans.includes(advertiser.plan) && (
                                    <tr>
                                        <td>
                                            {t('billing_subscription_click_overage_fee') + ' '}(
                                            {currencyFormatter(clickExceededPriceByUnit / 100)}/
                                            {numberFormatter(clickExceededUnitSize)})
                                        </td>
                                        <td style={{ textAlign: 'right' }} data-testid='overQuota'>
                                            {numberFormatter(clickExceeded)}
                                        </td>
                                        <td>
                                            +{' '}
                                            {currencyFormatter(clickExceededPrice / 100) +
                                                '/' +
                                                t('M')}
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <td colSpan={2}>
                                        {t('billing_subscription_table_total_monthly')}
                                    </td>
                                    <td>
                                        {currencyFormatter(fullPriceMonthly / 100) + '/' + t('M')}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Paper>
                    {showStripeInfo && (
                        <Footer>
                            {
                                nextBillingDateMonthly ? (
                                    t('billing_subscription_next_billing', {
                                        date: nextBillingDateMonthly,
                                        interpolation: { escapeValue: false },
                                    })
                                ) : (
                                    <>
                                        {t('billing_subscription_subscribed_until')}{' '}
                                        {moment
                                            .unix(advertiser.subscribedTo)
                                            .format(t('date_format'))}
                                        <br />
                                    </>
                                )
                                // need to set escapeValue: false to have good display of date
                            }{' '}
                            {renderNextPlanDowngraded()}
                        </Footer>
                    )}
                </>
            )}

            <Footer>
                {showStripeInfo && paymentMethodCard && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {t('billing_subscription_card_info', {
                            interpolation: { escapeValue: false },
                            brand: _.upperFirst(paymentMethodCard.brand),
                            lastDigits: paymentMethodCard.last4,
                            date: paymentMethodCard.exp_month + '/' + paymentMethodCard.exp_year,
                        })}
                        <Tooltip title={t('billing_subscription_card_update')}>
                            <IconButton
                                size='small'
                                onClick={() => dispatch(openAdvertiserUpdatePaymentMethodDialog())}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                <div
                    style={{
                        fontSize: '14px',
                    }}
                >
                    {t('billing_subscription_account_created_at')}{' '}
                    {moment.unix(advertiser.createdAt).format(t('date_format'))}
                </div>
            </Footer>
            {renderSuccessDialog()}
        </FormPageLayout>
    );
}

//#region Header
function Header() {
    const advertiser = useAppSelector((state) => state.advertiser.currentAdvertiser)!;
    const areBillingDetailsComplete = hasAdvertiserCompletedDetails(advertiser);

    return (
        <div className='advertiserHeader'>
            <Title />
            <div className='billingActionsWrapper'>
                {advertiser.status !== 'free' && <SubscriptionButton />}
                {!areBillingDetailsComplete && <CompleteDetailsButton />}
            </div>
        </div>
    );
}

function SubscriptionButton() {
    const advertiser = useAppSelector((state) => state.advertiser.currentAdvertiser)!;
    const shouldDisplayUpdateBtn = getShouldDisplayUpdateBtn(advertiser);
    return shouldDisplayUpdateBtn ? <SubscriptionUpdateButton /> : <SubscriptionCreateButton />;

    function getShouldDisplayUpdateBtn(advertiser: Advertiser) {
        if (advertiser.paymentMethod === 'bankwire') {
            return true;
        }

        const hasCanceled = hasAdvertiserCanceled(advertiser);
        if (hasCanceled) {
            return false;
        }

        const mainSubscription = getMainSubscription(advertiser);
        if (mainSubscription == null) {
            return false;
        }

        if (mainSubscription.status === 'incomplete_expired') {
            return false;
        }

        return true;
    }
}

function SubscriptionUpdateButton() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const advertiser = useAppSelector((state) => state.advertiser.currentAdvertiser!);
    const isUnpaid = getIsUnpaid(advertiser);
    const dataTip = getDataTip(advertiser);

    return (
        <div className='billingActionButton'>
            <Tooltip title={dataTip}>
                <Button
                    color='primary'
                    variant='contained'
                    disabled={
                        advertiser.paymentMethod === 'bankwire' ||
                        advertiser.status === 'frozen' ||
                        isUnpaid
                    }
                    onClick={() => dispatch(openAdvertiserUpdateSubscriptionDialog())}
                >
                    {t('billing_subscription_update_button')}
                </Button>
            </Tooltip>
        </div>
    );

    function getDataTip(advertiser: Advertiser) {
        const defaultValue = null;
        if (advertiser.paymentMethod === 'bankwire') {
            return t('billing_subscription_update_button_bankwire_tooltip');
        }

        const isUnpaid = getIsUnpaid(advertiser);
        if (isUnpaid) {
            return t('billing_subscription_update_button_unpaid_tooltip');
        }

        return defaultValue;
    }
}

function SubscriptionCreateButton() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const advertiser = useAppSelector((state) => state.advertiser.currentAdvertiser!);
    const areBillingDetailsComplete = hasAdvertiserCompletedDetails(advertiser);
    const dataTip = areBillingDetailsComplete
        ? ''
        : t('billing_subscription_subscribe_button_disabled_tooltip');
    return (
        <div className='billingActionButton'>
            <Tooltip title={dataTip}>
                <Button
                    color='primary'
                    variant='contained'
                    disabled={!areBillingDetailsComplete}
                    onClick={() => dispatch(openAdvertiserSubscribeDialog())}
                >
                    {!advertiser.isCancel
                        ? t('billing_subscription_subscribe_button')
                        : t('billing_subscription_resubscribe_button')}
                </Button>
            </Tooltip>
        </div>
    );
}

function CompleteDetailsButton() {
    const { t } = useTranslation();
    const { locale } = useParams();
    return (
        <Link to={'/' + locale + '/advertiser/billing/details'}>
            <Button color='secondary' className='billingActionButton' variant='contained'>
                {t('billing_subscription_complete_details_button')}
            </Button>
        </Link>
    );
}

function Title() {
    const { t } = useTranslation();
    const advertiser = useAppSelector((state) => state.advertiser.currentAdvertiser)!;
    const latestPaymentIntent = useAppSelector(
        (state) => state.advertiserBillingReducer.latestPaymentIntent.data,
    );
    const content = getTitleContent(advertiser, latestPaymentIntent, t);
    return (
        <FormPageTitle
            style={{
                marginBottom: '0rem',
            }}
        >
            {content}
        </FormPageTitle>
    );
}

function getTitleContent(advertiser: Advertiser, latestPaymentIntent, t) {
    const { status } = advertiser;
    const defaultValue = <Fragment>{t(`billing_subscription_title_${status}`)}</Fragment>;

    if (status === 'freetrial') {
        const trialDaysLeft = getTrialDaysLeft(advertiser);
        return (
            <Fragment>
                {t(`billing_subscription_title_${status}`)}
                <span className='trialDaysLeft'>
                    {t(`billing_subscription_title_freetrial_days_left`, { count: trialDaysLeft })}
                </span>
            </Fragment>
        );
    }

    const hasCanceled = hasAdvertiserCanceled(advertiser);
    if (hasCanceled) {
        const daysLeft = getSubscriptionDaysLeft(advertiser);
        return (
            <Fragment>
                {t('billing_subscription_title_cancelled')}
                {daysLeft > 0 && (
                    <Fragment>
                        {': '}
                        <span className='trialDaysLeft'>
                            {t(`billing_subscription_title_subscribedRest`, { count: daysLeft })}
                        </span>
                    </Fragment>
                )}
            </Fragment>
        );
    }

    const subscription = getMainSubscription(advertiser);
    if (subscription == null) {
        return defaultValue;
    }
    if (subscription.status === 'incomplete') {
        const errorInfo = buildErrorInfo(latestPaymentIntent);
        const content = t('billing_subscription_title_incomplete') + ' ' + errorInfo;
        return <Fragment>{content}</Fragment>;

        function buildErrorInfo(paymentIntent) {
            if (paymentIntent == null) {
                return '';
            } else if (paymentIntent.status === 'requires_payment_method') {
                return '(invalid card, retry with a different one)';
            } else if (paymentIntent.status === 'requires_action') {
                return '(error 3DS, retry authentication)';
            } else {
                return '';
            }
        }
    }

    return defaultValue;
}
//#endregion

//#region advertiser data utilities
function hasAdvertiserCanceled(advertiser: Advertiser) {
    if (advertiser.isCancel) {
        return true;
    }

    const mainSubscription = getMainSubscription(advertiser);
    const hasNoStripeSubscription = mainSubscription == null;
    if (hasNoStripeSubscription) {
        return false;
    }

    const hasCanceled = mainSubscription.status === 'canceled';
    return hasCanceled;
}

function getSubscriptionDaysLeft(advertiser: Advertiser) {
    const momentEnd = moment.unix(advertiser.subscribedTo);
    const momentNow = moment();
    const daysLeft = momentEnd.diff(momentNow, 'days');
    return daysLeft;
}

export function getTrialDaysLeft(advertiser: Advertiser) {
    const momentEnd = moment.unix(advertiser.trialEnds);
    const momentNow = moment();
    const daysLeft = momentEnd.diff(momentNow, 'days');
    return daysLeft;
}

function getIsUnpaid(advertiser: Advertiser) {
    const mainSubscription = getMainSubscription(advertiser);
    if (mainSubscription == null) {
        return false;
    }
    const unpaidStatuses = ['unpaid', 'incomplete', 'incomplete_expired', 'past_due'];
    const isUnpaid = unpaidStatuses.includes(mainSubscription.status);
    return isUnpaid;
}

function getMainSubscription(advertiser: Advertiser) {
    return advertiser.subscriptions?.find((sub) => sub.plans.some(isMainPlan));
}

function isMainPlan(
    plan: NonNullable<Advertiser['subscriptions']>[number]['plans'][number],
): boolean {
    return plans.some((planName) => plan.stripeId.toLowerCase().includes(planName));
}

//#endregion

function QuotaLinearProgress({
    id,
    title,
    value,
    max,
    formatter: _formatter,
}: {
    id: string;
    title: JSX.Element;
    value: number;
    max: number;
    formatter?: (value: number) => string;
}) {
    const { i18n } = useTranslation();
    const formatter = _formatter ?? new Intl.NumberFormat(i18n.language).format;
    return (
        <div data-testid='quotaLinearProgress' id={id}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <label
                    htmlFor={id}
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    {title}
                </label>
                <span>
                    <span id={`${id}-numerator`}>{formatter(value)}</span>
                    {' / '}
                    <span id={`${id}-denominator`}>{formatter(max)}</span>
                </span>
            </div>
            <LinearProgress
                id={id}
                variant='determinate'
                value={(100 * value) / max}
                color='secondary'
            />
        </div>
    );
}

function Footer({ children }: { children?: React.ReactNode }) {
    return (
        <footer
            style={{
                color: 'rgba(0, 0, 0, 0.5)',
                marginTop: '20px',
                fontSize: '14px',
            }}
        >
            {children}
        </footer>
    );
}

import { UserThirdParty } from '@libs/auth';

import { checkStatus } from '@api/common/utils';

const instaGraphApiUrl = 'https://graph.instagram.com/v21.0';

export async function getMyInfo({ accessToken }: { accessToken: string }) {
    const res = await fetch(
        `${instaGraphApiUrl}/me?access_token=${accessToken}&` +
            'fields=id,name,username,profile_picture_url,biography,website,followers_count',
    );
    await checkStatus(res);
    const data = await res.json();
    return { ...data, source: 'instagram' } as UserThirdParty;
}

import { CSSProperties } from 'react';

import { Box } from '@mui/material';

import aeLogo from './ae-logo.svg';

export function AvatarWithBorder({
    imageUrl,
    borderVariant = 'default',
}: {
    imageUrl: string | null | undefined;
    borderVariant?: 'instagram' | 'default';
}) {
    const borderColor: CSSProperties['background'] = (function () {
        switch (borderVariant) {
            case 'instagram':
                return 'linear-gradient(225deg, #8A3BEE 0%, #F200B7 40%, #FE9402 92%)';
            default:
                return '#FE9402';
        }
    })();

    return (
        <Box
            sx={{
                display: 'inline-block',
                padding: '3px',
                borderRadius: '50%',
                background: borderColor,
                height: '64px',
                width: '64px',
            }}
        >
            <Box
                sx={{
                    padding: '3px',
                    borderRadius: 'inherit',
                    background: 'white',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        borderRadius: 'inherit',
                        background: 'hsla(0, 0%, 95%, 1)',
                        display: 'grid',
                        placeItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {imageUrl ? (
                        <img
                            style={{ width: '100%', height: '100%', borderRadius: 'inherit' }}
                            src={imageUrl}
                        />
                    ) : (
                        <img src={aeLogo} />
                    )}
                </Box>
            </Box>
        </Box>
    );
}

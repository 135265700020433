import { useTranslation } from 'react-i18next';

import Box, { BoxProps } from '@mui/material/Box';

import { useUrlState } from '@hooks/useUrlState';

import { AuthMethod, authMethods, AuthProvider, authProviders } from '@libs/auth';
import { ParamTypesLocale, UserType } from '@libs/getSharedVar';

import { BillingPeriod } from '@api/advertiser/fetchCurrentAdvertiserData';

import logo from './logo-noir-300px.png';

export const authMethodParamKey = 'authMethod' as const;
export const authMethodPath = `:${authMethodParamKey}(` + authMethods.join('|') + ')?';
export type ParamsWithAuthMethod = ParamTypesLocale & { [authMethodParamKey]?: AuthMethod };

export const authProviderParamKey = 'authProvider' as const;
export const authProviderPath = `:${authProviderParamKey}(` + authProviders.join('|') + ')';
export type ParamsWithAuthProvider = ParamTypesLocale & { [authProviderParamKey]: AuthProvider };

export function getSignupUserType(): Exclude<UserType, 'admin'> {
    const isAdvertiser = !location.pathname.includes('publisher');
    return isAdvertiser ? 'advertiser' : 'publisher';
}

export function FormContentLayout({ sx, ...otherProps }: BoxProps) {
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ...sx,
            }}
            {...otherProps}
        />
    );
}

export function FormHeader({ sx, ...otherProps }: BoxProps) {
    const { t } = useTranslation();

    const isAdvertiser = !location.pathname.includes('publisher');

    const [plan] = useUrlState<string>('plan', 'track');
    const [currency] = useUrlState<string>('currency', 'eur');
    const [billingPeriod] = useUrlState<BillingPeriod>('billingPeriod', 'monthly');

    return (
        <Box
            sx={{
                textAlign: 'center',
                marginBottom: '3rem',
                ...sx,
            }}
            {...otherProps}
        >
            <a
                href={CONFIG.website_url}
                target='_blank'
                rel='noopener noreferrer'
                style={{ display: 'block' }}
            >
                <img src={logo} alt='Logo Affilae' className='logo' />
            </a>
            <Box
                sx={{
                    marginTop: '1rem',
                    color: '#555',
                    fontSize: '1.125rem',
                }}
            >
                {t(isAdvertiser ? 'advertiser_signup_baseline' : 'publisher_signup_baseline')}
            </Box>
            {isAdvertiser ? (
                <p style={{ color: 'rgba(0, 0, 0, 0.3)', fontSize: '12px' }}>
                    {plan} - {currency} -{' '}
                    {billingPeriod === 'monthly' ? t('generic_monthly') : t('generic_yearly')}
                </p>
            ) : (
                ''
            )}
        </Box>
    );
}

export const defaultSignupPages: Record<Exclude<UserType, 'admin'>, string> = {
    advertiser: '/:locale/signup',
    publisher: '/:locale/publisher-signup',
};

import './ProductPageRestriction.less';

import { useTranslation } from 'react-i18next';

import { Alert, Grid, Paper } from '@mui/material';
import Button from '@mui/material/Button';

import { styles } from '@components/advertiser/feeds/EmptyFeedsLanding';
import { FormPageLayout, FormPageTitle } from '@components/generic/Form';
import PageTabTitle from '@components/generic/PageTabTitle/PageTabTitle';

import forbiddenAccessPicture from './partnerDiscoveryNoAccess.svg';

const ProductPageRestriction = () => {
    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    return (
        <FormPageLayout>
            <PageTabTitle>{t('page_restriction_update_plan_title')}</PageTabTitle>
            <FormPageTitle style={{ marginTop: '2rem' }}>
                {t('page_restriction_update_plan_title')}
            </FormPageTitle>
            <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
                <Grid item md={12}>
                    <Alert severity='info'>{t('page_restriction_update_plan_alert')}</Alert>
                </Grid>
                <Grid item md={12}>
                    <div style={{ ...styles.subTitles, marginTop: '1rem', marginBottom: '1rem' }}>
                        {t('page_restriction_update_plan_subtitle')}
                    </div>
                </Grid>
                <Grid item md={5}>
                    <img
                        src={forbiddenAccessPicture}
                        alt='Forbidden-access'
                        style={{ paddingTop: '1.5rem', paddingRight: '1rem' }}
                    />
                </Grid>
                <Grid item md={7}>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Paper
                                sx={{
                                    padding: '2rem',
                                    height: '100%',
                                    textAlign: 'justify',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <div>{t('page_restriction_update_plan_text')}</div>
                                <div style={{ marginTop: '1rem', fontWeight: 'bold' }}>
                                    {t('page_restriction_update_plan_upgrade_plan')}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item md={12}>
                            <Paper
                                sx={{
                                    padding: '2rem',
                                    height: '100%',
                                    textAlign: 'justify',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <div style={{ padding: '0.5rem', marginBottom: '1rem' }}>
                                    {t('page_restriction_update_plan_reward')}
                                </div>
                                <div style={{ marginTop: '0.5rem' }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        href={t(
                                            `page_restriction_update_plan_sales_link_${locale}`,
                                        )}
                                    >
                                        {t('page_restriction_update_plan')}
                                    </Button>
                                    <Button
                                        variant='text'
                                        color='primary'
                                        href={
                                            CONFIG.website_url +
                                            t(`page_restriction_update_plan_app_contact_${locale}`)
                                        }
                                        sx={{ marginLeft: '1rem' }}
                                    >
                                        {t('page_restriction_update_plan_sales')}
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormPageLayout>
    );
};

export default ProductPageRestriction;

import _ from 'lodash';

import { getUserTypeFromUrl } from '@libs/getSharedVar';
import { getJwt } from '@libs/jwt';

import { getAPISubUrlForAdvertiser, getAPISubUrlForCurrentUser } from '@api/common/utils';

import { addFlash } from './common';

export const FETCH_VOUCHERS = 'FETCH_VOUCHERS';
export const FETCH_VOUCHERS_SUCCESS = 'FETCH_VOUCHERS_SUCCESS';
export const FETCH_VOUCHERS_FAILURE = 'FETCH_VOUCHERS_FAILURE';

export const CREATE_VOUCHER = 'CREATE_VOUCHER';
export const CREATE_VOUCHER_SUCCESS = 'CREATE_VOUCHER_SUCCESS';
export const CREATE_VOUCHER_FAILURE = 'CREATE_VOUCHER_FAILURE';

export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHER_SUCCESS';
export const DELETE_VOUCHER_FAILURE = 'DELETE_VOUCHER_FAILURE';

export const ON_RESET_VOUCHER_MESSAGE_ERROR = 'ON_RESET_VOUCHER_MESSAGE_ERROR';

export function getVouchersQueryString(queryCriteria) {
    let qs;
    const offset = queryCriteria.page * queryCriteria.limit;
    if (getUserTypeFromUrl() === 'advertiser') {
        qs =
            '?program=' +
            queryCriteria.program +
            '&limit=' +
            queryCriteria.limit +
            '&offset=' +
            offset +
            '&sort=' +
            queryCriteria.sort +
            '&orderBy=' +
            queryCriteria.orderBy;

        if (!_.isEmpty(queryCriteria.partnership)) {
            qs += '&partnership=' + queryCriteria.partnership;
        }

        if (queryCriteria.search) {
            qs += '&search=' + queryCriteria.search;
        }
        if (queryCriteria.blacklisted !== 'all' && queryCriteria.blacklisted !== undefined) {
            qs += '&blacklisted=' + queryCriteria.blacklisted;
        }
        if (queryCriteria.status !== undefined) {
            qs += '&status=' + queryCriteria.status;
        }
    } else if (getUserTypeFromUrl() === 'publisher') {
        qs =
            '?affiliateProfile=' +
            queryCriteria.affiliateProfile +
            '&limit=' +
            queryCriteria.limit +
            '&offset=' +
            offset +
            '&sort=' +
            queryCriteria.sort +
            '&orderBy=' +
            queryCriteria.orderBy;

        if (!_.isEmpty(queryCriteria.partnership)) {
            qs += '&partnership=' + queryCriteria.partnership;
        }
        if (queryCriteria.search) {
            qs += '&search=' + queryCriteria.search;
        }
        if (queryCriteria.blacklisted !== 'all' && queryCriteria.blacklisted !== undefined) {
            qs += '&blacklisted=' + queryCriteria.blacklisted;
        }
        if (queryCriteria.status !== undefined) {
            qs += '&status=' + queryCriteria.status;
        }
    }
    return qs;
}

export function fetchVouchers(queryCriteria) {
    const qs = getVouchersQueryString(queryCriteria);
    const url = getAPISubUrlForCurrentUser() + '/vouchers.list' + qs;
    const promise = fetch(url, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + getJwt() },
    });

    return {
        type: FETCH_VOUCHERS,
        payload: promise,
    };
}

export function fetchVouchersSuccess(queryCriteria, vouchers, total) {
    return {
        type: FETCH_VOUCHERS_SUCCESS,
        vouchers: vouchers,
        total: total,
        queryCriteria: queryCriteria,
    };
}

export function fetchVouchersFailure(queryCriteria, error) {
    return {
        type: FETCH_VOUCHERS_FAILURE,
        payload: error,
        queryCriteria: queryCriteria,
    };
}

export function deleteVoucher(ids) {
    const promise = fetch(getAPISubUrlForAdvertiser() + '/vouchers.delete', {
        method: 'POST',
        headers: {
            Authorization: getJwt(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ids: ids,
        }),
    });

    return {
        type: DELETE_VOUCHER,
        payload: promise,
    };
}

export function deleteVoucherSuccess() {
    return {
        type: DELETE_VOUCHER_SUCCESS,
    };
}

export function deleteVoucherFailure(error) {
    return {
        type: DELETE_VOUCHER_FAILURE,
        payload: error,
    };
}

export function onResetVoucherMessageError() {
    return {
        type: ON_RESET_VOUCHER_MESSAGE_ERROR,
    };
}

export const fetchVouchersThunk = (queryCriteria) => {
    return async (dispatch) => {
        dispatch(fetchVouchers(queryCriteria))
            .then(function (response) {
                return response.payload.json();
            })
            .then(function (result) {
                if (result.statusCode !== 200) {
                    dispatch(fetchVouchersFailure(queryCriteria, result.statusCode));
                } else {
                    dispatch(
                        fetchVouchersSuccess(
                            queryCriteria,
                            result.vouchers.data,
                            result.vouchers.total,
                        ),
                    );
                }
            });
    };
};

export const deleteVoucherThunk = (voucherId, queryCriteria) => {
    return async (dispatch) => {
        dispatch(deleteVoucher(voucherId))
            .then(function (response) {
                return response.payload.json();
            })
            .then(function (result) {
                if (result.statusCode !== 200) {
                    dispatch(deleteVoucherFailure(result));
                    addFlash('error', _t('voucher_deletion_failure'));
                } else {
                    dispatch(deleteVoucherSuccess());
                    addFlash('success', _t('voucher_deletion_success'));
                    dispatch(fetchVouchers(queryCriteria))
                        .then(function (response) {
                            return response.payload.json();
                        })
                        .then(function (result) {
                            if (result.statusCode !== 200) {
                                dispatch(fetchVouchersFailure(queryCriteria, result.statusCode));
                            } else {
                                dispatch(
                                    fetchVouchersSuccess(
                                        queryCriteria,
                                        result.vouchers.data,
                                        result.vouchers.total,
                                    ),
                                );
                            }
                        });
                }
            });
    };
};

import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SerializedError } from '@reduxjs/toolkit';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import ErrorIcon from '@mui/icons-material/Error';

import { Tooltip } from '@components/generic/Tooltip';

import { LoadingState, LoadingStatus } from '@libs/LoadingStatus';

import { ErrorView } from '../ErrorView';

interface LoadingViewProps {
    /**
     * Message to show under the loading indicator.
     */
    msg?: string;
    customStyle?: Record<'container', CSSProperties>;
}

/**
 * LoadingView is a component that shows a loading indicator with a message.
 */
export function LoadingView({ msg, customStyle }: LoadingViewProps) {
    const { t } = useTranslation();

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                ...customStyle?.container,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontSize: '14px',
                }}
            >
                <CircularProgress />
                <div style={{ marginTop: '1rem' }}>
                    {msg ?? <>{t('messaging_loading_view_basic_msg')}</>}
                </div>
            </div>
        </div>
    );
}

// OverlapLoader overlap the page - use to show a new data loading state
// while keeping the previous view (ex: changing page on a table)
export const OverlapLoader = (props: { customStyle?: CSSProperties; iconColor?: string }) => {
    const { customStyle, iconColor } = props;
    const defaultStyle: CSSProperties = {
        width: '90%',
        height: '90%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
    };
    const style = { ...defaultStyle, ...customStyle };

    return (
        <div style={style} onClick={(e) => e.stopPropagation()}>
            <CircularProgress size={'70px'} style={{ fill: iconColor }} />
        </div>
    );
};

interface LoadingHandlerInlineProps<Data> {
    loadingState: LoadingState<Data>;
    children?: ReactNode;
    className?: string;
    style?: React.CSSProperties;
}
export function LoadingHandlerInline<T>({
    loadingState,
    className,
    style = { marginLeft: '1rem' },
    children,
}: LoadingHandlerInlineProps<T>) {
    const { status, error } = loadingState;
    const { t } = useTranslation();

    return (
        <span className={className} style={style}>
            {(function () {
                if (status === 'idle' || status === 'success') {
                    return children;
                } else if (status === 'loading') {
                    return <CircularProgress size={20} />;
                } else {
                    return (
                        <Tooltip
                            title={error?.message ?? t('messaging_error_view_basic_msg').toString()}
                        >
                            <ErrorIcon color='error' fontSize='small' />
                        </Tooltip>
                    );
                }
            })()}
        </span>
    );
}

interface LoadingHandlerProps {
    status: LoadingStatus;
    children: ReactNode;
    startLoading?: () => void; // start loading and update status with result
    loadingMsg?: string;
    errorMsg?: string;
    errorComponent?: React.ElementType<{ msg?: string }>;
}
export function LoadingHandler({
    status,
    startLoading,
    children,
    loadingMsg,
    errorMsg,
    errorComponent: Error = ErrorView,
}: LoadingHandlerProps) {
    useEffect(() => {
        if (startLoading != null && status === 'idle') {
            startLoading();
        }
    }, [status]);

    switch (status) {
        case 'idle':
        case 'loading':
            return <LoadingView msg={loadingMsg} />;

        case 'failure':
            return <Error msg={errorMsg} />;

        case 'success':
            return <>{children}</>;
    }
}

export type LoadingHandler2Props<Data, Error extends SerializedError = SerializedError> = {
    loading: LoadingState<Data, Error>;
    children?: ReactNode | ((data: Data) => ReactNode);
    errorView?: string | ((error: Error) => ReactNode);
    loadingView?: string | ReactNode;
    disabled?: boolean;
};

// todo replace LoadingHandler with LoadingHandler2
export function LoadingHandler2<Data, Error extends SerializedError = SerializedError>({
    loading,
    errorView = (error) => <ErrorView msg={error.message} />,
    loadingView = <LoadingView />,
    children,
    disabled = false,
}: LoadingHandler2Props<Data, Error>) {
    if (disabled) {
        return <>{children}</>;
    }
    switch (loading.status) {
        case 'idle':
        case 'loading':
            return typeof loadingView === 'string' ? (
                <LoadingView msg={loadingView} />
            ) : (
                <>{loadingView}</>
            );
        // fragment are needed because a reactNode is not a valid jsx element

        case 'failure':
            return typeof errorView === 'function' ? (
                <>{errorView(loading.error)}</>
            ) : (
                <ErrorView msg={errorView} />
            );

        case 'success':
            return <>{typeof children === 'function' ? children(loading.data) : children}</>;
    }
}

export function LoadingHandlerWithRetry<Data, Error extends SerializedError = SerializedError>({
    onRetry,
    onCancel,
    children,
    cancelLabel,
    errorMsg = (error) => error.message,
    ...loadingHandlerProps
}: Omit<LoadingHandler2Props<Data, Error>, 'errorView' | 'loadingView'> & {
    onRetry: () => void;
    onCancel: () => void;
    cancelLabel?: string;
    errorMsg?: string | ((error: Error) => string | undefined);
}) {
    const { t } = useTranslation();
    const [retryCount, setRetryCount] = useState(0);

    function handleRetry() {
        onRetry();
        setRetryCount((c) => c + 1);
    }

    return (
        <LoadingHandler2
            {...loadingHandlerProps}
            errorView={(error) => (
                <ErrorView
                    msg={typeof errorMsg === 'function' ? errorMsg(error) : errorMsg}
                    actionButtons={
                        <>
                            <Button onClick={onCancel}>{cancelLabel ?? t('generic_cancel')}</Button>
                            <Tooltip
                                title={
                                    retryCount >= 3
                                        ? t('generic_too_many_attempt_failed').toString()
                                        : ''
                                }
                            >
                                <span>
                                    <Button
                                        variant='contained'
                                        onClick={handleRetry}
                                        disabled={retryCount >= 3}
                                    >
                                        {t('generic_retry')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </>
                    }
                />
            )}
        >
            {children}
        </LoadingHandler2>
    );
}

import {
    CLOSE_ALL_DIALOGS,
    FETCH_PROGRAMS_AM_DATA,
    FETCH_PROGRAMS_AM_DATA_FAILURE,
    FETCH_PROGRAMS_AM_DATA_LOADING,
    FETCH_PROGRAMS_AM_DATA_SUCCESS,
    OPEN_EDIT_AM_DIALOG,
    OPEN_EDIT_PAYMENT_OPTION_DIALOG,
    UPDATE_PROGRAM_AM_FIELDS,
    UPDATE_PROGRAM_PAYMENT_OPTION_FIELDS,
    UPDATE_PROGRAMS_AM_CRITERIA,
    UPDATE_PROGRAMS_AM_PROGRAM_DATA,
    UPDATE_PROGRAMS_AM_PROGRAM_DATA_FAILURE,
    UPDATE_PROGRAMS_AM_PROGRAM_DATA_SUCCESS,
    UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT,
    UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_FAILURE,
    UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_SUCCESS,
    UPDATE_PROGRAMS_AM_VISIBLE_COLUMNS,
    UPDATE_PROGRAMS_PAYMENT_OPTION_FAILURE,
    UPDATE_PROGRAMS_PAYMENT_OPTION_SUCCESS,
} from '@actions/programsAm';

import { PeriodRef } from '@components/generic/DateRangePicker';

import { IsoDate, MongoId } from '@libs/types';

// Initial query is for the last 3 months
const initialFrom = new Date();
initialFrom.setMonth(initialFrom.getMonth() - 3);
initialFrom.setHours(0, 0, 0, 0);

const initialTo = new Date();
initialTo.setHours(23, 59, 59, 999);

type State = {
    programsAm: {
        queryCriteria: {
            period: PeriodRef;
            categories: string[];
            mainCategory: string;
            countries: string[];
            verified: boolean | null;
            marketplace: boolean | null;
            am: boolean | null;
            paymentOption: boolean | null;
            subscriptionStatus: (null | 'trial' | 'active' | 'free' | 'cancel' | 'na')[];
            search: string;
            amManager: string;
        };
        loading: boolean;
        visibleColumns: string[];
        data: unknown;
        isOpenEditAmDialog: boolean;
        editAmFields: {
            programId: MongoId | null;
            programName: string;
            hasAm: boolean;
            amFrom: unknown | null;
            amTo: unknown | null;
            amManager: string;
            amTimeSpent: string;
            amSubscription: string;
            currency: string;
        };
        isOpenEditPaymentOptionDialog: boolean;
        editPaymentOptionFields: {
            programId: MongoId | null;
            programName: string;
            hasPaymentOption: boolean;
            hasSepa: boolean;
            levels: unknown[];
            currency: string;
        };
        error: unknown | null;
    };
};

const INITIAL_STATE: State = {
    programsAm: {
        queryCriteria: {
            period: 'last_30_days',
            categories: [],
            mainCategory: '',
            countries: [],
            verified: null,
            marketplace: null,
            am: null,
            paymentOption: null,
            subscriptionStatus: [],
            search: '',
            amManager: '',
        },
        loading: false,
        visibleColumns: [
            'ranking',
            'programName',
            'url',
            'mainCategory',
            'onMarketplace',
            'activeAffiliatesCount',
            'email',
        ],
        data: {},
        isOpenEditAmDialog: false,
        editAmFields: {
            programId: null,
            programName: '',
            hasAm: false,
            amFrom: null,
            amTo: null,
            amManager: '',
            amTimeSpent: '',
            amSubscription: '',
            currency: '',
        },
        isOpenEditPaymentOptionDialog: false,
        editPaymentOptionFields: {
            programId: null,
            programName: '',
            hasPaymentOption: false,
            hasSepa: false,
            levels: [],
            currency: '',
        },
        error: null,
    },
};

export default function (state = INITIAL_STATE, action): State {
    let error;

    switch (action.type) {
        case UPDATE_PROGRAMS_AM_CRITERIA:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    queryCriteria: action.queryCriteria,
                },
            };
        case UPDATE_PROGRAMS_AM_VISIBLE_COLUMNS:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    visibleColumns: action.visibleColumns,
                },
            };
        case FETCH_PROGRAMS_AM_DATA:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    loading: true,
                },
            };
        case FETCH_PROGRAMS_AM_DATA_SUCCESS:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    data: action.payload,
                    error: error,
                    loading: false,
                },
            };
        case FETCH_PROGRAMS_AM_DATA_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    data: {},
                    error: error,
                    loading: false,
                },
            };
        case FETCH_PROGRAMS_AM_DATA_LOADING:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    loading: true,
                },
            };
        case UPDATE_PROGRAMS_AM_PROGRAM_DATA:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                },
            };
        case UPDATE_PROGRAMS_AM_PROGRAM_DATA_SUCCESS:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    data: action.payload,
                },
            };
        case UPDATE_PROGRAMS_AM_PROGRAM_DATA_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    error: error,
                },
            };
        case OPEN_EDIT_AM_DIALOG:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    isOpenEditAmDialog: true,
                    editAmFields: action.fields,
                },
            };
        case OPEN_EDIT_PAYMENT_OPTION_DIALOG:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    isOpenEditPaymentOptionDialog: true,
                    editPaymentOptionFields: action.fields,
                },
            };
        case CLOSE_ALL_DIALOGS:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    isOpenEditAmDialog: false,
                    isOpenEditPaymentOptionDialog: false,
                },
            };
        case UPDATE_PROGRAM_AM_FIELDS:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    editAmFields: action.fields,
                },
            };
        case UPDATE_PROGRAM_PAYMENT_OPTION_FIELDS:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    editPaymentOptionFields: action.fields,
                },
            };
        case UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                },
            };
        case UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_SUCCESS:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    data: action.payload,
                    isOpenEditAmDialog: false,
                },
            };
        case UPDATE_PROGRAMS_AM_PROGRAM_MANAGEMENT_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    error: error,
                },
            };
        case UPDATE_PROGRAMS_PAYMENT_OPTION_SUCCESS:
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    data: action.payload,
                    isOpenEditPaymentOptionDialog: false,
                },
            };
        case UPDATE_PROGRAMS_PAYMENT_OPTION_FAILURE:
            error = action.payload || { message: action.payload.message };
            return {
                ...state,
                programsAm: {
                    ...state.programsAm,
                    error: error,
                },
            };
        default:
            break;
    }

    return state;
}

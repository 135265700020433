import { getJwt } from '@libs/jwt';
import { MongoId } from '@libs/types';

import { checkStatus, checkStatusForProductor, getProductorBaseUrl } from '@api/common/utils';

export interface FeedTagsDetectorResponse {
    fieldnames: string[];
    id: string;
    mapping_detected: [];
    item: any; //list of {key: value: string}
    xml_item?: string;
    json_item?: string;
}

export interface FeedTagsDetectorPayload {
    url: string;
    lang: string;
}

type ProductItem = {
    [key: string]: string;
};

type MappingDetected = {
    description: string;
    landing_page: string;
    price: string;
};

type ProductData = {
    fieldnames: string[];
    mapping_detected: MappingDetected;
    id: string;
    item: ProductItem;
};

type feedCategoryDetectorData = {
    message: string;
    nb_categories: number;
};

type feedAnalyzeData = {
    message: string;
    nb_categories: number;
};

export async function feedTagDetector(payload: FeedTagsDetectorPayload) {
    const url = `${getProductorBaseUrl()}tags?url=${payload.url}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: getJwt() || '',
        },
    });
    await checkStatusForProductor(response, 200, payload.lang);
    const body = await response.json();
    return body;
}

export async function feedProductSearchTagDetector(
    payload: FeedTagsDetectorPayload,
): Promise<ProductData> {
    const url = `${getProductorBaseUrl()}product_search_tags?url=${payload.url}`;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: getJwt() || '',
        },
    });
    await checkStatusForProductor(response, 200, payload.lang);
    const body = await response.json();
    return body;
}

export async function feedCategoryDetector(id: MongoId): Promise<feedCategoryDetectorData> {
    const url = `${getProductorBaseUrl()}product_search_categories_detection?id=${id}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: getJwt() || '',
        },
    });
    await checkStatus(response, 200);
    const body = await response.json();
    return body;
}

export async function feedAnalyze(feedId: MongoId): Promise<feedAnalyzeData> {
    const url = `${getProductorBaseUrl()}product_search_analyzer?id=${feedId}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: getJwt() || '',
        },
    });
    await checkStatus(response, 200);
    const body = await response.json();
    return body;
}

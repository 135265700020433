import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';

import AlreadyLoggedPage from '@components/nav/redirectPages/AlreadyLoggedPage';

import { getAuthStatus } from '@libs/auth';
import { ParamTypes } from '@libs/getSharedVar';
import { useAccessToken } from '@libs/jwt';

export function RequireFullyAuth({ children }: { children: JSX.Element }) {
    const { i18n } = useTranslation();
    const { side } = useParams<ParamTypes>();
    const token = useAccessToken(side);
    const adminToken = useAccessToken('admin');

    if (getAuthStatus(token) !== 'full') {
        if (getAuthStatus(adminToken) === 'full') {
            return <Redirect to={`/${i18n.language}/admin`} />;
        } else {
            return (
                <Redirect
                    to={`/${i18n.language}/login?next=${location.pathname}${location.search}`}
                />
            );
        }
    }

    return children;
}

export function RequireNoAuth({ children }: { children: JSX.Element }) {
    const token = useAccessToken();
    if (getAuthStatus(token) !== 'none') {
        return <AlreadyLoggedPage />;
    }
    return children;
}

import { MongoId } from '@libs/types';

import { AuthThirdParty } from '@api/common/types';

import { checkStatus, getAPIBaseUrl } from '../common/utils';

export type TpLinkStatus =
    | {
          isLinked: true;
          potentialAssociatedProfiles: never;
      }
    | {
          isLinked: false;
          potentialAssociatedProfiles?: SubEntityPreview[];
      };

export type SubEntityPreview = {
    id: MongoId;
    title: string;
    logo_url: string | null;
};

export default async function getTpLinkStatus(authTp: AuthThirdParty): Promise<TpLinkStatus> {
    const response = await fetch(getAPIBaseUrl() + 'common/users/get-third-party-link-status', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(authTp),
    });
    await checkStatus(response, 200);
    return await response.json();
}

import {
    ADVERTISER_BILLING_LOADING,
    CANCEL_SUBSCRIPTION_ADVERTISER_FAILURE,
    CANCEL_SUBSCRIPTION_ADVERTISER_SUCCESS,
    CLOSE_ALL_DIALOGS,
    CLOSE_CONFIRMATION_SUCCESS_DIALOG,
    FETCH_ADVERTISER_INVOICES_FAILURE,
    FETCH_ADVERTISER_INVOICES_START,
    FETCH_ADVERTISER_INVOICES_SUCCESS,
    FETCH_LATEST_PAYMENT_INTENT_FAILURE,
    FETCH_LATEST_PAYMENT_INTENT_REQUEST,
    FETCH_LATEST_PAYMENT_INTENT_SUCCESS,
    listPaymentMethods,
    OPEN_ADVERTISER_SUBSCRIBE_DIALOG,
    OPEN_ADVERTISER_UPDATE_PAYMENT_METHOD_DIALOG,
    OPEN_ADVERTISER_UPDATE_SUBSCRIPTION_DIALOG,
    SUBSCRIBE_ADVERTISER_FAILURE,
    SUBSCRIBE_ADVERTISER_SUCCESS,
    UPDATE_ADVERTISER_PAYMENT_METHOD_FAILURE,
    UPDATE_ADVERTISER_PAYMENT_METHOD_LOADING,
    UPDATE_ADVERTISER_PAYMENT_METHOD_SUCCESS,
    UPDATE_INVOICE_FILTER,
    UPDATE_SUBSCRIPTION_ADVERTISER_FAILURE,
    UPDATE_SUBSCRIPTION_ADVERTISER_LOADING,
    UPDATE_SUBSCRIPTION_ADVERTISER_SUCCESS,
    VERIFY_3DS_ADVERTISER_SUCCESS,
} from '@actions/advertiserBilling';
import { InvoiceFilter } from '@pages/advertiser/BillingPage/InvoicesTab';

import { AdvertiserPlanAvailable } from '@api/advertiser/fetchCurrentAdvertiserData';
import { PaymentMethod } from '@api/advertiser/listPaymentMethods';

import LoadingStatus from './utils/loadingStatus';

const subscribeDialogEmptyFields = {
    currency: null,
    plan: null,
};

type State = {
    loading: boolean;
    isLoading: boolean;
    error: unknown | null;
    subscribeDialog: {
        isOpen: boolean;
        loading: boolean;
        action: 'subscribe' | 'update';
        fields: {
            currency: string | null;
            plan: AdvertiserPlanAvailable | null;
        };
        paymentIntentStatus: 'requires_action' | 'succeeded' | null; // maybe other value?
        paymentIntentSecret: unknown | null;
        success: boolean;
    };
    updatePaymentMethodDialog: {
        isOpen: boolean;
        loading: boolean;
    };
    paymentMethods: PaymentMethod[] | null;
    invoices: [];
    invoicesLoadingStatus: LoadingStatus | null;
    invoicesFilter: InvoiceFilter;

    latestPaymentIntent: {
        isLoading: boolean;
        data: unknown | null;
        error: unknown | null;
    };
};

const INITIAL_STATE: State = {
    loading: false,
    isLoading: false, // duplicated?
    error: null,
    subscribeDialog: {
        isOpen: false,
        loading: false,
        action: 'subscribe', //can be 'subscribe' or 'update'
        fields: subscribeDialogEmptyFields,
        paymentIntentStatus: null,
        paymentIntentSecret: null,
        success: false,
    },
    updatePaymentMethodDialog: {
        isOpen: false,
        loading: false,
    },
    paymentMethods: null,
    invoices: [],
    invoicesLoadingStatus: null,
    invoicesFilter: {
        period: 'year_to_date',
        currency: null,
        searchText: null,
        statusList: [],
        typesList: [],
    },

    latestPaymentIntent: {
        isLoading: false,
        data: null,
        error: null,
    },
};

export default function (state = INITIAL_STATE, action): State {
    switch (action.type) {
        case ADVERTISER_BILLING_LOADING:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_SUBSCRIPTION_ADVERTISER_LOADING:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    loading: true,
                },
            };
        case SUBSCRIBE_ADVERTISER_SUCCESS:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    loading: false,
                    paymentIntentStatus: action.paymentIntent ? action.paymentIntent.status : null,
                    paymentIntentSecret: action.paymentIntent
                        ? action.paymentIntent.client_secret
                        : null,
                },
            };
        case UPDATE_SUBSCRIPTION_ADVERTISER_SUCCESS:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    loading: false,
                    paymentIntentStatus: action.paymentIntent ? action.paymentIntent.status : null,
                    paymentIntentSecret: action.paymentIntent
                        ? action.paymentIntent.client_secret
                        : null,
                    success: true,
                },
            };
        case VERIFY_3DS_ADVERTISER_SUCCESS:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    loading: false,
                    paymentIntentStatus: null,
                    paymentIntentSecret: null,
                },
            };

        case SUBSCRIBE_ADVERTISER_FAILURE:
        case UPDATE_SUBSCRIPTION_ADVERTISER_FAILURE:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    loading: false,
                },
            };
        case CANCEL_SUBSCRIPTION_ADVERTISER_SUCCESS:
        case CANCEL_SUBSCRIPTION_ADVERTISER_FAILURE:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    loading: false,
                },
            };
        case listPaymentMethods.fulfilled.type:
        case UPDATE_ADVERTISER_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethods: action.payload,
            };
        case UPDATE_ADVERTISER_PAYMENT_METHOD_LOADING:
            return {
                ...state,
                updatePaymentMethodDialog: {
                    ...state.updatePaymentMethodDialog,
                    loading: true,
                },
            };
        case UPDATE_ADVERTISER_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                updatePaymentMethodDialog: {
                    ...state.updatePaymentMethodDialog,
                    loading: false,
                },
            };
        case OPEN_ADVERTISER_SUBSCRIBE_DIALOG:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    isOpen: true,
                    action: 'subscribe',
                    success: false,
                },
            };

        case OPEN_ADVERTISER_UPDATE_SUBSCRIPTION_DIALOG:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    isOpen: true,
                    action: 'update',
                },
            };
        case OPEN_ADVERTISER_UPDATE_PAYMENT_METHOD_DIALOG:
            return {
                ...state,
                updatePaymentMethodDialog: {
                    ...state.updatePaymentMethodDialog,
                    isOpen: true,
                },
            };
        case CLOSE_ALL_DIALOGS:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    isOpen: false,
                    loading: false,
                    fields: subscribeDialogEmptyFields,
                    paymentIntentStatus: null,
                    paymentIntentSecret: null,
                },
                updatePaymentMethodDialog: {
                    isOpen: false,
                    loading: false,
                },
            };

        case CLOSE_CONFIRMATION_SUCCESS_DIALOG:
            return {
                ...state,
                subscribeDialog: {
                    ...state.subscribeDialog,
                    success: false,
                },
            };

        case UPDATE_INVOICE_FILTER: {
            return {
                ...state,
                invoicesFilter: action.filter,
            };
        }

        case FETCH_ADVERTISER_INVOICES_START: {
            const loadingStatus = new LoadingStatus({ ...state.invoicesLoadingStatus });
            if (loadingStatus.isLoading === true) {
                return { ...state };
            }

            loadingStatus.isLoading = true;
            return {
                ...state,
                invoicesLoadingStatus: loadingStatus,
                isLoading: true,
            };
        }

        case FETCH_ADVERTISER_INVOICES_SUCCESS: {
            const loadingStatus = new LoadingStatus({ ...state.invoicesLoadingStatus });
            if (loadingStatus.isLoading !== true) {
                return { ...state };
            }

            loadingStatus.isComplete = true;
            loadingStatus.isLoading = false;
            loadingStatus.isError = false;

            const invoices = action.invoices || [];
            return {
                ...state,
                invoicesLoadingStatus: loadingStatus,
                isLoading: false,
                invoices,
            };
        }

        case FETCH_ADVERTISER_INVOICES_FAILURE: {
            const loadingStatus = new LoadingStatus({ ...state.invoicesLoadingStatus });
            if (loadingStatus.isLoading !== true) {
                return { ...state };
            }

            loadingStatus.isComplete = true;
            loadingStatus.isLoading = false;
            loadingStatus.isError = true;
            return {
                ...state,
                invoicesLoadingStatus: loadingStatus,
                isLoading: false,
            };
        }

        case FETCH_LATEST_PAYMENT_INTENT_REQUEST: {
            const latestPaymentIntent = {
                isLoading: true,
                data: null,
                error: null,
            };
            return { ...state, latestPaymentIntent };
        }
        case FETCH_LATEST_PAYMENT_INTENT_SUCCESS: {
            const latestPaymentIntent = {
                isLoading: false,
                data: action.paymentIntent,
                error: null,
            };
            return { ...state, latestPaymentIntent };
        }
        case FETCH_LATEST_PAYMENT_INTENT_FAILURE: {
            const latestPaymentIntent = {
                isLoading: false,
                data: null,
                error: action.error,
            };
            return { ...state, latestPaymentIntent };
        }

        default:
            break;
    }

    return state;
}

import { getJwt } from '@libs/jwt';
import { LocaleUpper } from '@libs/locales';
import { Currency, MongoId, Timestamp } from '@libs/types';

import { SubEntity } from '@api/common/types';

import { checkStatus, getAPISubUrlForAdvertiser } from '../common/utils';
import { GroupRuleLevel, TRuleMode, TRulePayoutType } from './group/types';

export type StringByLocale = Partial<Record<LocaleUpper, string>>;
export enum UrlType {
    sharp = 'sharp',
    parameter = 'parameter',
    redirect = 'redirect',
    s2s = 's2s',
}
export type Attribution = 'share' | 'last' | 'first';

export type Authorization = 'yes' | 'no' | 'ask';

export type ProgramStatus = 'enabled' | 'paused' | 'deleted' | 'pending';

// wip
export type ProgramInfo<IsOwner extends boolean = true> = SubEntity & {
    allowPaypal: boolean;
    advertiser: string;
    urlTracking: string;
    teaserTranslation: StringByLocale;
    descriptionTranslation: StringByLocale;
    terms: string;
    termsTranslation: StringByLocale;

    currencies: Currency[];

    categories: string[];
    mainCategory: string;
    minimumAmount: number;
    onMarketplace: boolean;
    approveAffiliates: boolean;
    isPrivate: boolean;
    isActivated: boolean;
    affiliateManager: string;
    urlTrackingSlugMode: boolean;

    authorizeBrandName: Authorization;
    authorizeTextLink: Authorization;
    authorizeNewsletter: Authorization;
    authorizePopUpAndPopUnder: Authorization;
    authorizeBuyKeywords: Authorization;
    authorizeCoregistration: Authorization;

    attribution: Attribution;
    cpc: unknown;
    css: unknown;
    urlType: UrlType;
    defaultAffiliateWeight: number;
    advertiserWeight: number;
    sessionLength: number;
    rules: Rule[]; // always empty when caller is not the owner of the program
    groups: Group[]; // always empty when caller is not the owner of the program
    hasAm: boolean;
    hasPaymentOption: boolean;

    sessionTimeout: number; // duration in seconds

    geotargeting: string[];
    /** list of affiliate types teaser */
    affiliateTypes: string[];

    status: ProgramStatus;
    updatedAt?: Timestamp;

    tags: string[];

    facebookUrl: string;
    tiktokUrl: string;
    twitterUrl: string;
    instagramUrl: string;
    pinterestUrl: string;
    youtubeUrl: string;

    programManager:
        | string
        | {
              _id: MongoId;
              firstName: string;
              lastName: string;
          };
} & (IsOwner extends true
        ? {
              /** list of ip without space separated by comma */
              ipBlacklist?: string[];
              privateKey?: string;
              notificationMessage: boolean;
              notificationConversion: boolean;
              stats?: unknown;
              adTrackingIdIncrement?: unknown;
          }
        : {});
export interface Rule {
    id: MongoId;
    name: string;
    createdAt: Timestamp;
    currencies: string[];
    currency: unknown;
    lockingDelay: unknown;
    hasOnlinePayment: boolean;
    revenueSharing: unknown;
    defaultConversionStatus: unknown;
    unpaidConversionStatus: unknown;
    payoutKind: unknown;
    trackingLifetime: unknown;
    deletedAt?: Timestamp;
    mode: 'cpc | cpa | cpl | cpm';
}

export interface Group {
    id: MongoId;
    name: string;
    createdAt: Timestamp;
    isDefault: boolean;
    isHiddren: boolean;
    cpc: unknown;
    rules: GroupRule[];
    deletedAt?: Timestamp;
}

export interface GroupRule {
    id: MongoId;
    name: string;
    currency: unknown;
    mode: TRuleMode;
    payoutType: TRulePayoutType;
    levels: Record<string, GroupRuleLevel[]>;
}

export default async function fetchProgramInfo(programId: MongoId): Promise<ProgramInfo> {
    const url = getAPISubUrlForAdvertiser() + `/programs.info?program=${programId}`;
    const response = await fetch(url, { headers: { Authorization: getJwt() || '' } });
    await checkStatus(response, 200);
    const body = await response.json();
    const program = body.program.data;
    return program;
}

export type ProgramInfoResponse = {
    program: ProgramInfo;
};

import { Auth } from '@api/common/types';

import { checkStatus, getAPIBaseUrl } from '../common/utils';

interface LoginResponse {
    accessToken: string;
    refreshToken: string;
}

export default async function login({
    auth,
    getRefreshToken = false,
}: {
    auth: Auth;
    getRefreshToken?: boolean;
}): Promise<LoginResponse> {
    const response = await fetch(getAPIBaseUrl() + 'common/users.signin', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            auth,
            getRefreshToken,
        }),
    });
    await checkStatus(response, 200);
    return (await response.json()) as LoginResponse;
}
